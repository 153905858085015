import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { setIsClose, setClearIsFormSended } from "../../redux/ModalSlice/ModalSlice";
import { useTelegramMessage } from "../../utils/request";
import closeButton from "../../assets/icons/closeButton.png";
import "./Modal.css";
import sendedIcon from '../../assets/icons/SendedIcon.png'


function Modal() {
  const [userName, setUserName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [userContact, setUserContact] = useState("");
  const [contactError, setContactError] = useState("");
  const [currentLocation, setCurrentLocation] = useState("");
  const [currentPrice, setCurrentPrice] = useState("");
  const location = useLocation();
  const dispatch = useDispatch();
  let timeoutId;
  const isActive = useSelector((state) => state.modal.isActive);
  const response = useSelector((state) => state.modal.isFormSended);
  const [isResponseActive, setIsResponseActive] = useState(false);
  const isCourse = /side\/.+/.test(location.pathname);
  const isDeepSoulPage = location.pathname === "/woman-side/deep-soul";
  const isSkvirtMassagePage = location.pathname === "/man-side/skvirt-massage";
  const isWomanSide = location.pathname.includes("woman-side");
  const { sendTelegramMessage } = useTelegramMessage();

  useEffect(() => {
    switch (true) {
      case /man-side\/potencial-chlena/.test(location.pathname):
        setCurrentLocation("ПОТЕНЦІАЛ ЧЛЕНА 2.0");
        setCurrentPrice("250")
        break;
      case /man-side\/skvirt-massage/.test(location.pathname):
        setCurrentLocation("Сквірт масаж");
        setCurrentPrice("від 250")
        break;
      case /man-side\/kyni-do-skvirta/.test(location.pathname):
        setCurrentLocation("КУНІ ДО СКВІРТУ");
        setCurrentPrice("200")
        break;
      case /man-side\/dominant/.test(location.pathname):
        setCurrentLocation("ДОМІНАНТ Pro Max");
        setCurrentPrice("130")
        break;
      case /man-side\/pozy-18/.test(location.pathname):
        setCurrentLocation("ПОЗИ 18+");
        setCurrentPrice("130")
        break;
      case /man-side\/anal-do-skvirta/.test(location.pathname):
        setCurrentLocation("АНАЛ ДО СКВІРТА");
        setCurrentPrice("130")
        break;
      case /woman-side\/deep-soul/.test(location.pathname):
        setCurrentLocation("ГЛИБОКА ДУША");
        setCurrentPrice("від 145")
        break;
      case /woman-side\/aquapark-girls/.test(location.pathname):
        setCurrentLocation("АКВАПАРК Girls");
        setCurrentPrice("180")
        break;
      case /woman-side\/tilo-kinchay/.test(location.pathname):
        setCurrentLocation("ТІЛО КІНЧАЙ");
        setCurrentPrice("350")
        break;
      case /woman-side\/pozy18/.test(location.pathname):
        setCurrentLocation("ПОЗИ 18+");
        setCurrentPrice("130")
        break;
      case /woman-side\/anal-do-skvirta/.test(location.pathname):
        setCurrentLocation("АНАЛ ДО СКВІРТА");
        setCurrentPrice("130")
        break;
      case /woman-side\/lingam-prostata/.test(location.pathname):
        setCurrentLocation("ЛІНГАМ-ПРОСТАТА");
        setCurrentPrice("160")
        break;
      case /man-side\/kiss/.test(location.pathname):
        setCurrentLocation("ЦІЛУЙ");
        setCurrentPrice("50")
        break;
      case /woman-side\/kiss/.test(location.pathname):
        setCurrentLocation("ЦІЛУЙ");
        setCurrentPrice("50")
        break;
      default:
        setCurrentLocation("Home");
        break;
    }
  }, [location.pathname]);

  useEffect(() => {
    setIsResponseActive(response);

    if (isResponseActive) {
      timeoutId = setTimeout(() => {
        dispatch(setClearIsFormSended());
      }, 10000);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [response, isResponseActive, dispatch]);

  const handleModalClose = () => {
    dispatch(setIsClose());
    dispatch(setClearIsFormSended())
  };

  const handleModalBodyClick = (e) => {
    e.stopPropagation();
  };

  const handleContactChange = (e) => {
    setUserContact(e.target.value);
    setContactError(false)
  };

  const handleNameChange = (e) => {
    setUserName(e.target.value);
    setNameError(false)
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let nameErrorFlag = false;
    let contactErrorFlag = false;
   

    if (userName.length < 1) {
      setNameError(true);
      nameErrorFlag = true;
      e.preventDefault();
    } else {
      setNameError(false);
    }

    if (userContact.length > 2) {
      setContactError(false);
    } else {
      setContactError(true);
      e.preventDefault();
      contactErrorFlag = true;
    }

    if (nameErrorFlag || contactErrorFlag) {
      return;
    } else if (!nameErrorFlag && !contactErrorFlag) {
      const message = `\u{2753}Консультація\u{2753}\nИмя: ${userName}\nКонтакт: ${userContact}`;
      const messageCourse = `\u{02764}Курс\u{02764}\nКурс: ${currentLocation}\nИмя: ${userName}\nКонтакт: ${userContact}`;

      await sendTelegramMessage(isCourse && !isDeepSoulPage && !isSkvirtMassagePage ? messageCourse : message);
      setUserName("");
      setUserContact("");
    }
  };

  return (
    <div className={`modal__form ${isActive ? "active" : ""}`}>
      <div onClick={handleModalClose} className="modal__overlay">
        <div onClick={handleModalBodyClick} className={`modal__form-body ${isResponseActive ? "hidden" : ""}`}>
          <p className="modal__form-title body-text">
            <img
              onClick={handleModalClose}
              src={closeButton}
              alt="закрити форму"
              className="close__button"
            />
            {isCourse && !isDeepSoulPage && !isSkvirtMassagePage ? (
              <span className="modal__form-title body-text">
                Заявка на придбання курсу, обраний:{" "}
                <span
                  className={`modal__form-title body-text ${
                    isWomanSide ? "woman-color" : "man-color"
                  }`}
                >
                  {currentLocation}
                </span>{" "}
                <p>Вартість: <span className={`modal__form-title body-text ${
                    isWomanSide ? "woman-color" : "man-color"
                  }`}>{currentPrice} $</span></p>
              </span>
            ) : (
              <span className="modal__form-title body-text">
                Заявка на консультацію Відповімо на всі твої питання
              </span>
            )}{" "}
          </p>
          <input
            placeholder="Ім’я"
            value={userName}
            onChange={handleNameChange}
            className={`modal__form-input ${isWomanSide ? "woman" : "man"} ${
              nameError ? "error" : ""
            } body-text`}
            type="text"
          />
          <input
            placeholder="Номер, або нік в телеграмі"
            value={userContact}
            onChange={handleContactChange}
            className={`modal__form-input ${isWomanSide ? "woman" : "man"} ${
              contactError ? "error" : ""
            } body-text`}
            type="text"
          />
          <p className="modal__form-attention body-text">*Лишаючи нік свого телеграму, переконайтеся що у Вас відкриті повідомлення!</p>
          <button
            onClick={handleSubmit}
            className={`modal__form-button ${
              isWomanSide ? "woman" : "man"
            } body-text`}
            type="submit"
          >
            Відправити
          </button>
          <a href="https://t.me/hub1317_admin" target={"_blank"} rel={"noreferrer"} className={`modal__form-link ${
              isWomanSide ? "woman" : "man"
            } body-text`}>Написати менеджеру</a>
        </div>
        <div className={`modal__form-body-response ${isResponseActive ? "active" : ""}`}>
          <div className="modal__form-body-response-wrapper">
        <img onClick={handleModalClose}  src={closeButton} alt="закрити форму" className="close__button-response" />
            <img src={sendedIcon} alt="форма відправлено успішко картинка" className="sended-icon" />
            <p className="sended-title text-title-two">Форма успішно відправлена</p>
            <p className="sended-description body-text">Менеджер з вами зв'яжеться найближчим часом!</p>
            </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
