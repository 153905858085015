export const deepSoulProgramArr = [
    {
        title: "Модуль 1: Basic",
        subtitle: "7 Уроків: Ази порно мінету зібрані в одному місці. Без зайвої води - чітко і по ділу.",
        modules: [
            {
                courseCount: "1",
                courseName: "Рвотний рефлекс ",
                text: "- що це і навіщо? Пояснюємо які психологічні та фізичні фактори його викликають",
            },
            {
                courseCount: "2",
                courseName: "Долаємо рвотний ",
                text: "- як переступити через ментальний барʼєр, який не дає тобі взяти глибоко в глотку?",
            },
            {
                courseCount: "3",
                courseName: "Практичні вправи від Тіни Кросс, ",
                text: "що допоможуть прибрати рвотний рефлекс, не залежно від розміру та часу ",
            },
            {
                courseCount: "4",
                courseName: "Техніка дихання ",
                text: "- як заспокоїти свій мозок та контролювати подачу кисню? Практика вільного дихання.",
            },
            {
                courseCount: "5",
                courseName: "Не ігноруй яйця ",
                text: "- це найчутливіша зона! Як працювати з його мошонкою, поки глотка зайнята членом?",
            },
            {
                courseCount: "6",
                courseName: "Топ пози для глибокої глотки. ",
                text: "Як робити мінет в будь-якому положенні, з урахуванням вигину члена та його розміру.",
            },
            {
                courseCount: "7",
                courseName: "Діалоги під час мінету ",
                text: "- як і що сказати під час, аби змусить його закінчити так глибоко в твою глотку, як ніколи.",
            },
        ],
    },
    {
        title: "Модуль 2: PRO",
        subtitle: "8 Уроків: Підкорюйте не лише його тіло, а й вчіться керувати його мозком!",
        modules: [
            {
                courseCount: "1",
                courseName: "Чутливість члена ",
                text: "як визначити і чому  це база для його оргазмів? Гайд по збору інформації на окремих зонах його тіла.",
            },
            {
                courseCount: "2",
                courseName: "Психологічні травми, ",
                text: "що впливають на ваше сексуальне життя: практика від сексолога, щоб він зміг з тобою кінчати",
            },
            {
                courseCount: "3",
                courseName: "Масаж лінгама ",
                text: "- усі техніки, як довести його до піку не лише глоткою, а й за допомогою рук під час мінету",
            },
            {
                courseCount: "4",
                courseName: "Заволодій мозком, ",
                text: "а не лише членом - прокачай жіночу домінацію та впевненість, аби він не мав шансу не підкоритися",
            },
            {
                courseCount: "5",
                courseName: "Порно мінет ",
                text: "- влаштуй йому оральне шоу, після якого ти точно відчуєш, що таке вдячний шанувальник",
            },
            {
                courseCount: "6",
                courseName: "Сходіть у секс шоп, ",
                text: "аби розпалити уяву до якісного сексу. Поради по засобам та девайсам для професійного мінету. ",
            },
            {
                courseCount: "7",
                courseName: "Не втрачай лібідо ",
                text: "- як смоктати член у своє задоволення? Чому варто полюбити мінет, а не смоктати «бо він захотів»",
            },
            {
                courseCount: "8",
                courseName: "Домінуй на колінах ",
                text: "- техніка підкорення чоловіка. Тут зона твого контролю - тож бери в руки не лише член, а й процес",
            },
        ],
    },
    {
        title: "Модуль 3: VIP",
        subtitle: "16 Уроків: Готуйтесь – вам поклонятимуться! Маючи усі ці знання, ви станете тою самою ФАТАЛЬНОЮ ЖІНКОЮ!",
        modules: [
            {
                courseCount: "1",
                courseName: "ЖМЖ та МЖМ ",
                text: "- секрети орального сексу втрьох! Як не розгубитись? Знання про усі види пестощів.",
            },
            {
                courseCount: "2",
                courseName: "Мінет випускниці ",
                text: "- офлайн навчання з випускницею курсу. Працюємо над глибиною та техніками в режимі лайф",
            },
            {
                courseCount: "3",
                courseName: "Відвертий влог ",
                text: "- вчимо дівчину глибокому мінету з нуля . Розберемо усі труднощі перших заглотів та помилки",
            },
            {
                courseCount: "4",
                courseName: "Сценарії сексу, ",
                text: "які дозволять забути, що таке рутинний відсос… Нові формати - це антиген звиканню",
            },
            {
                courseCount: "5",
                courseName: "Ти не запасна! ",
                text: "Як не відчувати себе зайвою та бути завжди бажаною жінкою для нього під час сексу втрьох.",
            },
            {
                courseCount: "6",
                courseName: "Стань головною жінкою ",
                text: "у його житті та  впевнено йди на секс експерименти! Втілюй усі фантазії від тріо до оргії.",
            },
            {
                courseCount: "7",
                courseName: "Влаштуй шоу: ",
                text: "як бути в сексі впевненою, сильною та жіночною. Вчимось стогнати, рухатись та приймати себе",
            },
            {
                courseCount: "8",
                courseName: "Ідеальний ракурс: ",
                text: "як у сексі виглядати завжди якнайкраще? Забудь про сором і ти більше не вимикатимеш світло",
            },
            {
                courseCount: "9",
                courseName: "Ти будеш єдиною. ",
                text: "Зроби його залежним від себе, і після згадки про вашу ніч, він знайде усі способи, аби це повторити",
            },
            {
                courseCount: "10",
                courseName: "Керуй його фетишами. ",
                text: "Дізнайся та втіль правильно його фантазії, щоб це не змогла зробити інша. Техніка «анти зрада»",
            },
            {
                courseCount: "11",
                courseName: "Ефект ЖМЖ  без третьої ",
                text: "- як за допомогою мастурбатора і повʼязки на очах чоловіка,  створити ефект сексу втрьох?",
            },
            {
                courseCount: "12",
                courseName: "Масаж члена, ",
                text: "від якого його ніжки затрусяться і ти почуєш «я такого ще не відчував» - техніка від Тіни Кросс",
            },
            {
                courseCount: "13",
                courseName: "Королівський мінет ",
                text: "- техніка з максимальним заглотом по яйця. Просунутий рівень глибокого - до самого корінця.",
            },
            {
                courseCount: "14",
                courseName: "Він стогнатиме ",
                text: "- вчимо чоловіка кінчати гучно і стираємо психологічні барʼєри, які не дають йому насолоджуватись.",
            },
            {
                courseCount: "15",
                courseName: "Слоу мо мінет ",
                text: "- техніка, яка змусить його член тремтіти від задоволення. Формат, що переверне сприйняття мінету",
            },
            {
                courseCount: "16",
                courseName: "Втрата лібідо ",
                text: "- в чому причина зниження твого лібідо? Працюємо над поверненням і його підвищенням.",
            },
        ],
    },
    
]

export const skvirtMassageProgramArr = [
    {
        title: "Модуль 1: Йоні масаж",
        subtitle: "В модулі зібрані 17 уроків: Базова інформація про те як доводити дівчину до сквірту. Основні теми модуля:",
        modules: [
            {
                courseCount: "1",
                courseName: "Твій арсенал масажу. ",
                text: "Необхідні Матеріали для масажу, атмосфери, які зроблять процес для тебе і дівчини незабутнім.",
            },
            {
                courseCount: "2",
                courseName: "Словесний та тілесний збір інформації ",
                text: "- як створити персональну масажну карту під дівчину, аби ваша взаємодія пройшла ідеально?",
            },
            {
                courseCount: "3",
                courseName: "Положення дівчини під час масажу ",
                text: "- дізнайся, як покласти дівчину, аби їй і тобі було комфортно і ти зміг діставати всі зони на її тілі, які тобі потрібні.",
            },
            {
                courseCount: "4",
                courseName: "Техніки масажу тіла ",
                text: "- дивись та повторюй . Леон продемонструє  як зробити масаж голови, спини, ніг, сідниць, аби дівчина розслабилась.",
            },
            {
                courseCount: "5",
                courseName: "Йоні масаж. ",
                text: "З якої пози краще починати робити Йоні масаж, як наносити лубрикант на пісічку та якими рухами масажувати її йоні.",
            },
            {
                courseCount: "6",
                courseName: "Пози для максимальної близькості та з якої можна перейти до сексу. ",
                text: "Леон показує положення твого та її тіла, в яких ви будете в максимальному збуджуючому контакті, та з якої легко буде перейти до сексу.",
            },
        ],
    },
    {
        title: "Модуль 2: АКВАПАРК",
        subtitle: "В модулі зібрані 137 уроків: Занурся глибше в тему жіночого задоволення, де розібрано як своїми руками навчитись доводити її до сквірту та розберешся з аспектами поведінки та спілкування з дівчатами в інтимному плані. Основні теми модуля:",
        modules: [
            {
                courseCount: "1",
                courseName: "Як знаходити дівчат для масажу? ",
                text: "Дізнайся де знаходити дівчат і як вести діалог перед масажем аби не злякати її?",
            },
            {
                courseCount: "2",
                courseName: "Набір масажиста. ",
                text: "Які необхідні матеріали для масажу треба мати при собі?",
            },
            {
                courseCount: "3",
                courseName: "Збір інформації. ",
                text: "Зроби  персональну масажну  карту та слідуй їй під час масажу.",
            },
            {
                courseCount: "4",
                courseName: "Техніки масажу тіла. ",
                text: "На власні очі ти побачиш як автор курсу робить масаж, які техніки використовують, аби дівчина розслабились і відключила голову.",
            },
            {
                courseCount: "5",
                courseName: "Йоні масаж. ",
                text: "Бери та повторюй, але враховуй її особливості. ",
            },
            {
                courseCount: "6",
                courseName: "Пози для сквірту та положення масажу тіла. ",
                text: "Дізнаєшся які пози краще підходять для сквірту не тільки руками а й членом.",
            },
            {
                courseCount: "7",
                courseName: "Кунілінгус. ",
                text: "Правила і техніки куніку від Леон 13.",
            },
            {
                courseCount: "8",
                courseName: "Анальний секс. ",
                text: "Як правильно підготувати дівчину та подарувати їй незабутній досвід цього виду сексу?",
            },
            {
                courseCount: "9",
                courseName: "Чоловіче здоров’я. ",
                text: "Розберешся зі своїми гормонами, отримаєш вправи для підтримання здорової простати та створиш свій, унікальний, чоловічий аромат.",
            },
            {
                courseCount: "10",
                courseName: "Ревнощі. ",
                text: "Розберешся чому вони виникають, як тобі шкодять і Як їх позбутись раз і назавжди.",
            },
        ],
    },
    {
        title: "Модуль 3: Shark VIP MASTER",
        subtitle: "В модулі зібрані 192 уроки: Стань профі в жіночій насолоді та зі зворотнім звʼязком від автора курсу, Леона13, розбери на деталі теми сквірту, оргазмів, масажу та розкриття сексуального потенціалу, який ховається в твоїй дівчині. Основні теми модуля:",
        modules: [
            {
                courseCount: "1",
                courseName: "Масажний набір профі. ",
                text: "Як скласти свій персональний арсенал для масажу, аби не нашкодити дівчині, а створити відчуття VIP підходу.",
            },
            {
                courseCount: "2",
                courseName: "Правила незабутнього масажу та сексу від Леон 13. ",
                text: "Отримай секретні техніки і фішки, які дівчина точно оцінить.",
            },
            {
                courseCount: "3",
                courseName: "Спілкування з дівчиною. ",
                text: "Стань профі у зборі інформації, аби дізнатись найтаємніші бажання дівчини та подарувати насолоду від них.",
            },
            {
                courseCount: "4",
                courseName: "Масажні рухи для тіла та СКВІРТУ. ",
                text: "Побачиш на відео як це робить Леон та повторюй на дівчині.",
            },
            {
                courseCount: "5",
                courseName: "Збуджуючо ",
                text: "- розслабляючий масаж голови. Зрозумієш як відключати голову дівчини та породжувати в ній збудження.",
            },
            {
                courseCount: "6",
                courseName: "Експеременти з її тілом. ",
                text: "Аби твій інтим, сквірт і масаж  не перетворювались в рутину - дивись що ти можеш з нею робити.",
            },
            {
                courseCount: "7",
                courseName: "Дослідження її тіла. ",
                text: "Дівчина вкрита ерогенними зонами, які сплять. Знайди їх і розбуди.",
            },
            {
                courseCount: "8",
                courseName: "Нестандартні запити та ситуації  з дівчатами. ",
                text: "Отримуєш набір дій та підходів до нетипових ситуацій дівчат в плані інтиму, їх досвіду. Будеш знати як діяти.",
            },
            {
                courseCount: "9",
                courseName: "Анатомія статевих органів дівчини. ",
                text: "Зрозумій як і що працює, навіщо і як за допомогою цих знань надати їй насолоду.",
            },
            {
                courseCount: "10",
                courseName: "ПРОФЕСІЙНИЙ ЙОНІ МАСАЖ. ",
                text: "Отримай набір авторських рухів від Леона, здивуй її та насолоджуйся реакцію і враженнями після.",
            },
            {
                courseCount: "11",
                courseName: "Анальний секс. ",
                text: "Основні правила анального сексу де дівчина не боїться, а отримує насолоду.",
            },
            {
                courseCount: "12",
                courseName: "Як знайомитись з дівчатами. ",
                text: "Зрозумієш де шукати, як не боятись підходити і що говорити, аби створити трафік з дівчат.",
            },
            {
                courseCount: "13",
                courseName: "Способи заробітку. ",
                text: "Важливо вміти не тільки якісно сквіртити дівчину, але й заробляти кошти.",
            },
            {
                courseCount: "14",
                courseName: "Як довго не кінчати? ",
                text: "У багатьох є такий запит. Отримаєш рекомендації і способи як збільшити статевий акт, так і зменшити, коли не можеш довго закінчити.",
            },
            {
                courseCount: "15",
                courseName: "Чоловіче здоровʼя. ",
                text: "Дізнайся як мати високий тестостерон, відрегулювати рівень своїх гормонів і бути здоровим.",
            },
            {
                courseCount: "16",
                courseName: "Пози для масажу ,оргазмів та сквірту. ",
                text: "Наглядно побач пози для масажу, сквірту, сексу, які нададуть максимум відчуттів та задоволення.",
            },
            {
                courseCount: "17",
                courseName: "Як має виглядати масаж від А до Я. ",
                text: "Побачиш всі етапи від спілкування до рухів руками, аби нічого не впустити.",
            },
            {
                courseCount: "18",
                courseName: "Лайфхаки та секрети в спілкуванні та масажі з дівчатами. ",
                text: "Отримай персональний досвід від Леона, аби ти був готовий до будь-якої ситуації.",
            },
            {
                courseCount: "19",
                courseName: "Цнотливі жінки. ",
                text: "Дізнаешся як правильно позбавляти дівчину цноти, аби не нашкодити їй.",
            },
            {
                courseCount: "20",
                courseName: "Ексклюзивні вебінари ",
                text: "- відповіді на запитання учасників. Маєш змогу задати персональне питання і отримати відповідь від Леона",
            },
        ],
    },
    
]

export const imNewProgramArrMan = [
    {
        title: (<>
        <span>Чоловічій курс</span> <span className="man-color">Опануй сквірт</span></>),
        subtitle: "В модулі зібрані 17 уроків: Базова інформація про те як доводити дівчину до сквірту. Основні теми модуля:",
        modules: [
            {
                courseCount: "1",
                courseName: "Базові основи про жіночу насолоду. ",
                text: "Дізнайся що тобі знадобиться аби подарувати дівчині незабутні оргазми.",
            },
            {
                courseCount: "2",
                courseName: "Бонусний урок. 1000 $ за масаж. ",
                text: "Дізнаєшся як зробити так аби дівчата готові були платити тобі за масаж великі кошти.",
            },
            {
                courseCount: "3",
                courseName: "«Прелюдія» перед сквіртом. ",
                text: "Які важливі нюанси слід проговорити і що зробити перед початком взаємодії з дівчиною?",
            },
            {
                courseCount: "4",
                courseName: "Йоні масаж до сквірту. ",
                text: "Ввійди в неї так, щоб вона сквіртонула,але з урахуванням її особливостей.",
            },
            {
                courseCount: "5",
                courseName: "Нативна підготовка дівчини до анального сексу. ",
                text: "Побачиш пози, і кути входження в дівчину, які стимулюють певні точки,які відповідають за насолоду під час АС",
            },
            {
                courseCount: "6",
                courseName: '«Сценарій сексу. Перший дотик". ',
                text: "Отримаєш набір наглядних ,практичних дій ,завдяки яким зрозумієш з чого починати перший інтимний  контакт  з дівчиною та підготовку до нього",
            },
            {
                courseCount: "7",
                courseName: 'Філософія вивчення насолоди. ',
                text: "Яке життя чекає тебе, якщо ти будеш володіти інформацію про жінок, оргазми та психологію сексу",
            },
            {
                courseCount: "8",
                courseName: 'Бонусний відеоурок (Чоловіча цінність). ',
                text: "Якість нашого життя визначають наші переконання і погляди на певні речі. Усвідом хто ти, чому ти гідний кращого і не погоджуйся на менше",
            },
        ],
    },
   
    
]

export const imNewProgramArrWoman = [
    {
        title: (<>
        <span>Жіночий курс</span> <span className="woman-color">Бери глибоко</span></>),
        subtitle: "Тут ти знайдеш базу знань для жінок, котрі змінять не лише. сприйняття світу сексу, а й твоє життя. Час відчути себе незамінною для нього та стати його найсолодшою залежністю",
        modules: [
            {
                courseCount: "1",
                courseName: "Як врятувати стосунки завдяки масажу? ",
                text: "Як змінити те до чого він звик, щоб відчувати тебе по новому та забути про дрочку?",
            },
            {
                courseCount: "2",
                courseName: "Що його розслабить максимально? ",
                text: "Створюємо правильну атмосферу - умови повного релаксу для усіх його органів чуття",
            },
            {
                courseCount: "3",
                courseName: "Як кайфувати від процесу обом? ",
                text: "Найзручніші позиції, в яких від масажу ти не втомишся, та які засоби використовувати",
            },
            {
                courseCount: "4",
                courseName: "Як повернути чутливість члена? ",
                text: "Прибираємо монотонність, аби він відчував кожен твій рух та навіть подих у 2Х форматі",
            },
            {
                courseCount: "5",
                courseName: "Як зробити чоловіка залежним від тебе? ",
                text: "Створюємо позитивний досвід еротичного масажу",
            },
            {
                courseCount: "6",
                courseName: "Як діяти якщо ти новачок? ",
                text: "Лайфхак від Тіни Кросс, аби відчувати себе впевненою, навіть якщо ти тільки почала вивчати",
            },
            {
                courseCount: "7",
                courseName: "Як зробити його щедрим? ",
                text: "Як зробити його щедрим? Тактики спілкування, аби ваш чоловік не жалів для тебе нічого, та з радістю вкладався",
            },
            {
                courseCount: "8",
                courseName: "Вакуумна спіральна техніка мінету ",
                text: "- покажи йому, що таке мінет, як у справжньому порнофільмі",
            },
            {
                courseCount: "9",
                courseName: "Мінет на колінах. ",
                text: "Урок після якого ти будеш не просто сосати, а розкриєш свою сексуальність через свого чоловіка",
            },
        ],
    },
   
    
]