import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { IM_NEW_ROUTE, MANSIDE_ROUTE, WOMANSIDE_ROUTE } from "./utils/constants";
import ManSidePage from "./pages/ManSidePage/ManSidePage";
import WomanSidePage from "./pages/WomanSidePage/WomanSidePage";
import MainPage from "./pages/MainPage/MainPage";
import AppRouter from "./components/AppRouter/AppRouter";
import CoursePage from "./pages/CoursePage/CoursePage";

import {
  analArr,
  AquaExArr,
  AquaProArr,
  dominantArr,
  KissArr,
  KyniArr,
  manCourses,
  PotencialArr,
  pozyArr,
} from "./utils/arraysMan";
import { aquaGArr, deepArr, lingamArr, tiloKArr, womanCourses } from "./utils/arraysWoman";
import AllCoursesPage from "./pages/AllCoursesPage/AllCoursesPage";
import { deepSoulProgramArr, skvirtMassageProgramArr } from "./utils/courseProgramsArrays";
import { deepSoulCardsArr, skvirtMassageCardsArr } from "./utils/cardsArr";
import ImNewPage from "./pages/ImNewPage/ImNewPage";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<AppRouter />}>
          <Route index element={<MainPage />} />
          <Route path={MANSIDE_ROUTE} element={<ManSidePage />} />
          <Route path={WOMANSIDE_ROUTE} element={<WomanSidePage />} />
          <Route path={IM_NEW_ROUTE} element={<ImNewPage />} />
          {/* <Route
            path={"/man-side/aquapark-pro-max-vip"}
            element={
              <CoursePage
                urlCover={manCourses[3].urlCover}
                aboutUrl={manCourses[3].imageSrc}
                price={manCourses[3].price}
                aboutTitle={"Аквапарк Pro Max VIP"}
                aboutTextFirst={
                  "ТРАХАЙ ТА ЗАРОБЛЯЙ Тут лише профі! Знання з курсу допоможуть тобі не лише задовільняти свою дівчину на найвищому рівні, а навіть потрапити в індустрію 18+. Якщо ти готовий до того, що твої руки, член та пальці будуть пікантною темою для розмов у колі жінок - тоді я чекамо тебе в банді."
                }
                courseName={"Аквапарк Pro Max VIP"}
                isManSide={true}
                courseArr={AquaProArr}
                willLernArr={manCourses[3].willLernArr}
                isNewFormat={false}
                />
            }
          /> */}
          <Route
            path={"/man-side/skvirt-massage"}
            element={
              <CoursePage
              courseCardsArr={skvirtMassageCardsArr}
              courseProgramArr={skvirtMassageProgramArr}
                urlCover={manCourses[1].urlCover}
                aboutUrl={manCourses[1].imageSrc}
                price={manCourses[1].price}
                coverTitle={"Ти - творець її оргазмів"}
                aboutTitle={"Сквірт масаж"}
                aboutTextFirst={
                  "Майже Кожен чоловік бажає, аби дівчина  після проведеної ночі з ним сказала йому: «Мене ще ніхто ніколи так не трахав і не відчував як ти». І це не вигадані слова, це реалії тих чоловіків, хто пройшов курси по сексу у Леона13."
                }
                aboutTextSecond={"Ми не вчимо як найскоріше вставити член в дівчину і потикати вперед назад, ні. Інформація в курсах направлена дати тобі знання як розкрити дівчину, як дізнатись про її тіло більше, ніж вона сама про себе знає, як завести її в стан насолоди, в якому вона ніколи до цього не була, як подарувати їй незабутні оргазми,після яких вона буде дивитись на тебе зовсім іншими очима."}
                courseName={"Сквірт масаж"}
                courseArr={AquaExArr}
                isManSide={true}
                willLernArr={manCourses[1].willLernArr}
                isNewFormat={true}
              />
            }
          />
          <Route
            path={"/man-side/dominant"}
            element={
              <CoursePage
              isNewFormat={false}
                urlCover={manCourses[3].urlCover}
                aboutUrl={manCourses[3].imageSrc}
                price={manCourses[3].price}
                aboutTitle={"Домінант Pro Max"}
                aboutTextFirst={
                  "Курс який відриє тобі БДСМ світ. Домінація — це те, що виведе твій секс та життя на топ рівень. Тут ти побачиш контент, який заслуговує на перше місце у топі PORN HUB, тому запасайся серветками і вивчай."
                }
                courseName={"Домінант Pro Max"}
                courseArr={dominantArr}
                isManSide={true}
                willLernArr={manCourses[3].willLernArr}
              />
            }
          />
          <Route
            path={"/man-side/kyni-do-skvirta"}
            element={
              <CoursePage
              isNewFormat={false}
                urlCover={manCourses[2].urlCover}
                aboutUrl={manCourses[2].imageSrc}
                price={manCourses[2].price}
                aboutTitle={"Куні до Сквірту"}
                aboutTextFirst={
                  "ДОСИТЬ ЯЛОЗИТИ ЯЗИКОМ ЯК ВЕСЛОМ, вже сьогодні вона буде кінчати і сквіртувати від твого КУНІКА. Гарна новина для бішечок та лесбійок: тут ви знайдете топові уроки які підійдуть як для чоловіків, так і для дівчат! Після навчання ти більше не почуєш “сквірт — це щось на фантастичному”. Починай ламати ці міфи — твій язик здатний на все."
                }
                courseName={"Куні до Сквірту"}
                courseArr={KyniArr}
                isManSide={true}
                willLernArr={manCourses[2].willLernArr}
              />
            }
          />
          <Route
            path={"/man-side/anal-do-skvirta"}
            element={
              <CoursePage
              isNewFormat={false}
                urlCover={manCourses[5].urlCover}
                aboutUrl={manCourses[5].imageSrc}
                price={manCourses[5].price}
                aboutTitle={"Анал до сквірта"}
                aboutTextFirst={
                  "Відкрий новий вхід у світ насолоди. Чому цей курс у розділі для пар? Бо сквіртовий анал вимагає знань від двох. Тому ми зібрали найефективнішу підготовку до АНАЛЬНОГО СЕКСУ для дівчини і хлопця. Дівчата, готуй не лише свою жопу, а й мозок — тут ми пропрацює усі тригери, розтиснемо булочки та душу. А ви пацики готуйте член та мізки — тут потрібні вони обидва. READY?"
                }
                courseName={"Анал до сквірта"}
                courseArr={analArr}
                isManSide={true}
                willLernArr={manCourses[5].willLernArr}
              />
            }
          />
          <Route
            path={"/man-side/kiss"}
            element={
              <CoursePage
                              isNewFormat={false}
                urlCover={manCourses[6].urlCover}
                aboutUrl={manCourses[6].imageSrc}
                price={manCourses[6].price}
                aboutTitle={"Цілуй"}
                aboutTextFirst={
                  "Поцілунок — це те, з чого усе починається. І в кожного є та сама крінж історія поцілунку, яку твій мозок намагається забути. “Та що там його цілуватися” - фатальна помилка. Поцілунок — інтимніше навіть сексу. Саме він може або подарувати тобі найкращі стосунки твого життя або зруйнувати все ще на самому початку. Час вивести свої цілувальні здібрості на рівень ПРОФІ. Починай вивчати вже зараз"
                }
                courseName={"Цілуй"}
                courseArr={KissArr}
                isManSide={true}
                willLernArr={manCourses[6].willLernArr}
              />
            }
          />
          <Route
            path={"/man-side/pozy-18"}
            element={
              <CoursePage
              isNewFormat={false}
                urlCover={manCourses[4].urlCover}
                aboutUrl={manCourses[4].imageSrc}
                price={manCourses[4].price}
                aboutTitle={"Пози 18+"}
                aboutTextFirst={
                  "Такого формату сексу в тебе ще не було! Пози, які удосконалені та протестовані десятками пар. Такій деталізації позаздрить навіть Камасутра, адже цей курс — інструкція оновлення вашого інтиму до версії РRO. Навіть звичні пози ми перетворимо на витвори оргазмового мистецтва. Попереджаємо одразу — сусіди вас зненавидять і їх нічого не врятує від перевищення рівня децибелів."
                }
                courseName={"Пози 18+"}
                courseArr={pozyArr}
                isManSide={true}
                willLernArr={manCourses[4].willLernArr}
              />
            }
          />
          <Route
            path={"/man-side/potencial-chlena"}
            element={
              <CoursePage
              isNewFormat={false}
                urlCover={manCourses[0].urlCover}
                aboutUrl={manCourses[0].imageSrc}
                price={manCourses[0].price}
                aboutTitle={"Потенціал Члена 2.0"}
                aboutTextFirst={
                  "Потенціал - це те, що можна і треба розвивати. На курсі ти дізнаєшся, як повністю прокачати своє тіло та вивести свою чоловічу силу до рівня «термінатор»! Твій член стоятиме усю ніч, а трахати будеш не гірше порно акторів. Чітко виконуючи усі завдання курсу, ти забудеш, що таке швидкий фініш чи проблеми з падіннями. Разом зі спікерами розкриємо усі психологічні та фізичні чинники, які впливають на твій секс і загальне самопочуття. Вивчай, аби бути на поготові завджи і всюди, та фінішувати як вперше до глибокої старості."
                }
                courseName={"Потенціал Члена 2.0"}
                courseArr={PotencialArr}
                isManSide={true}
                willLernArr={manCourses[0].willLernArr}
              />
            }
          />
          <Route
            path={"/woman-side/deep-soul"}
            element={
              <CoursePage
              courseCardsArr={deepSoulCardsArr}
              courseProgramArr={deepSoulProgramArr}
              isNewFormat={true}
              coverTitle={"Кожна зможе глибше!"}
                urlCover={womanCourses[0].urlCover}
                aboutUrl={womanCourses[0].imageSrc}
                price={womanCourses[0].price}
                aboutTitle={"Глибока Душа"}
                aboutTextFirst={
                  "Мінет - це про задоволення обох. Тут не просто техніки і практики, як відсмоктати щоб він кінчив. Ні! Ми навчаємо жінок пізнавати себе та розкривати свою сексуальність через чоловіка. Адже смоктати треба так, щоб ти сама від себе кінчала."
                }
                aboutTextSecond={"Усе починається з голови, а тільки потім продовжується у глотці. Ми зібрали в цьому курсі як практичні так і психологічні методики, аби кожна заковтувала глибоко, легко і до останньої краплі. Тому, якщо ти готова щоразу чути його стогін і «де ти цього навчилась?» — тоді welcome to the club."}
                courseArr={deepArr}
                courseName={"Глибока Душа"}
                isManSide={false}
                willLernArr={womanCourses[0].willLernArr}
              />
            }
          />
          <Route
            path={"/woman-side/aquapark-girls"}
            element={
              <CoursePage
              isNewFormat={false}
                urlCover={womanCourses[1].urlCover}
                aboutUrl={womanCourses[1].imageSrc}
                price={womanCourses[1].price}
                aboutTitle={"Аквапарк Girls"}
                aboutTextFirst={
                  "Дізнайся на що спроможні твої ручки. Підкорити чоловіка повністю — ось якою силою ви  оволодієте. Техніки, які  не знайдеш у звичайних “масажках” - створені головним майстром еро технік в Україні: Leon13. Тільки попереджаємо одразу — у нього може з’явитись залежність від твоїх масажів. Якщо готова — тоді гоу)"
                }
                courseName={"Аквапарк Girls"}
                courseArr={aquaGArr}
                isManSide={false}
                willLernArr={womanCourses[1].willLernArr}
              />
            }
          />
          <Route
            path={"/woman-side/tilo-kinchay"}
            element={
              <CoursePage
              isNewFormat={false}
                urlCover={womanCourses[2].urlCover}
                aboutUrl={womanCourses[2].imageSrc}
                price={womanCourses[2].price}
                aboutTitle={"Тіло Кінчай"}
                aboutTextFirst={
                  "Тут ти навчиш СВОЄ ТІЛО - КІНЧАТИ! Цей курс - інструкція до ТЕБЕ, з якою ти розкриєш усі свої потаємні можливості та бажання."
                }
                courseName={"Тіло Кінчай"}
                courseArr={tiloKArr}
                isManSide={false}
                willLernArr={womanCourses[2].willLernArr}
              />
            }
          />
          <Route
            path={"/woman-side/pozy18"}
            element={
              <CoursePage
              isNewFormat={false}
                urlCover={womanCourses[3].urlCover}
                aboutUrl={womanCourses[3].imageSrc}
                price={womanCourses[3].price}
                aboutTitle={"Пози 18+"}
                aboutTextFirst={
                  "Такого формату сексу в тебе ще не було! Пози, які удосконалені та протестовані десятками пар. Такій деталізації позаздрить навіть Камасутра, адже цей курс — інструкція оновлення вашого інтиму до версії РRO. Навіть звичні пози ми перетворимо на витвори оргазмового мистецтва. Попереджаємо одразу — сусіди вас зненавидять і їх нічого не врятує від перевищення рівня децибелів."
                }
                courseArr={pozyArr}
                courseName={"Пози 18+"}
                isManSide={false}
                willLernArr={womanCourses[3].willLernArr}
              />
            }
          />
          <Route
            path={"/woman-side/anal-do-skvirta"}
            element={
              <CoursePage
              isNewFormat={false}
                urlCover={womanCourses[4].urlCover}
                aboutUrl={womanCourses[4].imageSrc}
                price={womanCourses[4].price}
                aboutTitle={"Анал до сквірта"}
                aboutTextFirst={
                  "Відкрий новий вхід у світ насолоди. Чому цей курс у розділі для пар? Бо сквіртовий анал вимагає знань від двох. Тому ми зібрали найефективнішу підготовку до АНАЛЬНОГО СЕКСУ для дівчини і хлопця. Дівчата, готуй не лише свою жопу, а й мозок — тут ми пропрацює усі тригери, розтиснемо булочки та душу. А ви пацики готуйте член та мізки — тут потрібні вони обидва. READY?"
                }
                courseArr={analArr}
                courseName={"Анал до сквірта"}
                isManSide={false}
                willLernArr={womanCourses[4].willLernArr}
              />
            }
          />
          <Route
            path={"/woman-side/lingam-prostata"}
            element={
              <CoursePage
              isNewFormat={false}
                urlCover={womanCourses[5].urlCover}
                aboutUrl={womanCourses[5].imageSrc}
                price={womanCourses[5].price}
                aboutTitle={"Лінгам-Простата"}
                aboutTextFirst={
                  "Він кінчить за секунди! Ти станеш тією, хто відведе чоловіка у світ насолоди і підкорить тіло, діставши до душі. Тут ти вчитимешся справжньому мистецтву заволодіння самим єством лише з допомогою своїх рук. Якщо ти готова стати його залежністю, то погнали."
                }
                courseName={"Лінгам-Простата"}
                courseArr={lingamArr}
                isManSide={false}
                willLernArr={womanCourses[5].willLernArr}
                
              />
            }
          />
          <Route
            path={"/woman-side/kiss"}
            element={
              <CoursePage
              isNewFormat={false}
                urlCover={womanCourses[6].urlCover}
                aboutUrl={womanCourses[6].imageSrc}
                price={womanCourses[6].price}
                aboutTitle={"Цілуй"}
                aboutTextFirst={
                  "Поцілунок — це те, з чого усе починається. І в кожного є та сама крінж історія поцілунку, яку твій мозок намагається забути. “Та що там його цілуватися” - фатальна помилка. Поцілунок — інтимніше навіть сексу. Саме він може або подарувати тобі найкращі стосунки твого життя або зруйнувати все ще на самому початку. Час вивести свої цілувальні здібрості на рівень ПРОФІ. Починай вивчати вже зараз"
                }
                courseName={"Цілуй"}
                courseArr={KissArr}
                isManSide={false}
                willLernArr={womanCourses[6].willLernArr}
              />
            }
          />
          <Route path={"all-courses"} element={<AllCoursesPage />}/>
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
