import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { setIsActive, setIsClose } from "../../redux/ModalSlice/ModalSlice";
import { WOMANSIDE_ROUTE, MANSIDE_ROUTE } from "../../utils/constants";
import "./Header.css";
import Hamburger from "hamburger-react";
import { setBurgerIsActive, setBurgerIsClose } from "../../redux/BurgerSlice/BurgerSlice";
import { useEffect, useState } from "react";


function Header() {
  const location = useLocation();
  const isDeepSoulPage = location.pathname === "/woman-side/deep-soul";
  const isSkvirtMassagePage = location.pathname === "/man-side/skvirt-massage";
  const dispatch = useDispatch();
  const isWomanSide = location.pathname.includes("woman-side");
  const isMainPage = location.pathname === "/";
  const isCourse = /side\/.+/.test(location.pathname);
  const hideHeader = isMainPage;
  const isActive = useSelector((state) => state.burger.isBurgerActive)
  const [isOpen, setOpen] = useState(isActive)
  const handleOpenModal = () => {
    dispatch(setIsActive());
    dispatch(setBurgerIsClose())
    setOpen(false)
  };

  useEffect(() => {
    setOpen(isActive)
  },[isActive])

  return (
    <header
      style={{ display: hideHeader ? "none" : "flex" }}
      className="header"
    >
      <div className="header__container">
        <Link
  to={isWomanSide ? "woman-side" : "man-side"}
  className={`${
    isWomanSide ? "logo-text-woman" : "logo-text-man"
  } header__side`}
>
  {location.pathname === "/im-new" ? (
    <>
      <span className="man-color">Guest </span>
      <span className="white-color">Room</span>
    </>
  ) : isWomanSide ? (
    <span className="woman-color">Woman </span>
  ) : (
    <span className="man-color">Man </span>
  )}
  {location.pathname !== "/im-new" && " Side"}
</Link>
        <div className="header__buttons">
          <Link to={"/im-new"} className="header__allcourses-button body-text">
            Я новенький
          </Link>
          <Link to={"all-courses"} className="header__allcourses-button body-text">
            Всі курси
          </Link>
          <Link
            className="change__side-button body-text"
            to={isWomanSide ? MANSIDE_ROUTE : WOMANSIDE_ROUTE}
          >
            {isWomanSide ? "Чоловічий простір" : "Жіночий простір"}
          </Link>
          <button
            onClick={handleOpenModal}
            className={` ${
              isWomanSide
                ? "header__form-button-woman"
                : "header__form-button-man"
            } body-text`}
          >
            {isCourse && !isDeepSoulPage && !isSkvirtMassagePage ? "Придбати" : "Консультація"}
          </button>
          <div className="burger__button">
            <Hamburger
             size={28}
             toggled={isOpen}
              onToggle={(toggled) => {
                if (toggled) {
                  dispatch(setIsClose())
                  dispatch(setBurgerIsActive())
                  setOpen(true)
                } else {
                  dispatch(setBurgerIsClose())
                  setOpen(false)
                }
              }}
            />
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
