import starImage from '../assets/icons/starImage.png'
import sharkImage from '../assets/icons/sharkImage.png'

export const deepSoulCardsArr = [
    {
        title: "Basic Package",
        include: ["Доступ до уроків модулю “Basic” назавжди", "Збірник «Гайд по сексу» на 34 сторінки", "Закрите комʼюніті з однодумцями",],
        notInclude: ["Доступ до уроків модулю “PRO” назавжди", "Звʼязок з кураторами 24/7", "Доступ до уроків модулю “VIP” назавжди","Доступ до ексклюзивних вебінарів","Можливість потрапити на офлайн практику",],
        lessonsCount: "8 відео-уроків",
        price: "145$",
        href: "https://t.me/hub1317_admin",
    },
    {
        title: "PRO Package",
        include: ["Доступ до уроків модулю “Basic” назавжди", "Збірник «Гайд по сексу» на 34 сторінки", "Закрите комʼюніті з однодумцями","Доступ до уроків модулю “PRO” назавжди","Звʼязок з кураторами 24/7"],
        notInclude: ["Доступ до уроків модулю “VIP” назавжди", "Доступ до ексклюзивних вебінарів", "Можливість потрапити на офлайн практику",],
        lessonsCount: "12 відео-уроків",
        price: "320$",
        href: "https://t.me/hub_1317_admin",
    },
    {
        title: "VIP Package",
        include: ["Доступ до уроків модулю “Basic” назавжди", "Збірник «Гайд по сексу» на 34 сторінки", "Закрите комʼюніті з однодумцями","Доступ до уроків модулю “PRO” назавжди","Звʼязок з кураторами 24/7","Доступ до уроків модулю “VIP” назавжди","Доступ до ексклюзивних вебінарів","Можливість потрапити на офлайн практику"],
        notInclude: [],
        lessonsCount: "23 відео-уроки",
        price: "650$",
        href: "https://t.me/admn1317",
        src: starImage
    },
]

export const skvirtMassageCardsArr = [
    {
        title: "Йоні масаж",
        include: ["Доступ до уроків модулю “Йоні масаж” назавжди", "Можливість потрапити в анкету випускників", "Гайд по сексу на 34 сторінки",],
        notInclude: ["Доступ до уроків модулю “Аквапарк” назавжди", "Комьюніті з однодумцями", "Можливість потрапити в анкету випускників","Доступ до уроків модулю “Shark VIP Master” назавжди","Звязок з ЛЕОНОМ 24/7","Доступ до ексклюзивних вебінарів","Можливість потрапити на безкоштовну офлайн зустріч","Оновлення уроків"],
        lessonsCount: "17 відео-уроків",
        price: "250$",
        href: "https://t.me/hub1317_admin",
    },
    {
        title: "АКВАПАРК",
        include: ["Доступ до уроків модулю “Йоні масаж” назавжди", "Можливість потрапити в анкету випускників", "Гайд по сексу на 34 сторінки","Доступ до уроків модулю “Аквапарк” назавжди","Комьюніті з однодумцями","Можливість потрапити в анкету випускників",],
        notInclude: ["Доступ до уроків модулю “Shark VIP Master” назавжди", "Звязок з ЛЕОНОМ 24/7", "Доступ до ексклюзивних вебінарів","Можливість потрапити на безкоштовну офлайн зустріч","Оновлення уроків"],
        lessonsCount: "137 уроків",
        price: "450$",
        href: "https://t.me/hub1317_admin",
    },
    {
        title: "Shark VIP Master",
        include: ["Доступ до уроків модулю “Йоні масаж” назавжди", "Можливість потрапити в анкету випускників", "Гайд по сексу на 34 сторінки","Доступ до уроків модулю “Аквапарк” назавжди","Комьюніті з однодумцями","Можливість потрапити в анкету випускників","Доступ до уроків модулю “Shark VIP Master” назавжди","Звязок з ЛЕОНОМ 24/7","Доступ до ексклюзивних вебінарів","Можливість потрапити на безкоштовну офлайн зустріч","Оновлення уроків"],
        notInclude: [],
        lessonsCount: "192 Уроки + Додаються нові",
        price: "650$",
        href: "https://t.me/hub1317_admin",
        src: sharkImage,
    },
]

export const ImNewWomanCardsArr = [
    {
        title: "Бери глибоко",
        include: ["Доступ до уроків курсу назавжди", "ЗНИЖКА 5% на будь-який наступний курс", "Знання, які зроблять його назавжди залежним від тебе", "Індивідуальні умови при проходження наступних курсів"],
        notInclude: [""],
        lessonsCount: "9 Відео-уроків",
        price: "1500 грн",
        href: "https://t.me/hub1317_admin",
    },
   
]

export const ImNewManCardsArr = [
    {
        title: "Опануй сквірт",
        include: ["Доступ до уроків курсу назавжди", "ЗНИЖКА 5% на будь-який наступний курс", "Знання, завдяки яким дівчина отримає незабутній оргазм", "Індивідуальні умови при проходження наступних курсів"],
        notInclude: [""],
        lessonsCount: "7 відео-уроків",
        price: "1500 грн",
        href: "https://t.me/hub1317_admin",
    },
   
]