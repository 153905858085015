// import { useEffect, useRef } from "react";
// import ReactPlayer from "react-player";
import "./CoursePageCover.css";


function CoursePageCover({ urlCover, isNewFormat, coverTitle, isManSide }) {

  return (
    <div className="course__cover">
      <div className="course__cover-player">
        <img src={urlCover} alt="фото курсу" className="course__cover-image" />
        {
          isNewFormat && 
          <h2 className={`course__cover-title text-title-one ${isManSide ? "" : "w"}`}>{coverTitle}</h2>
        }
      </div>
      <div className="side__cover-fade"></div>
    </div>
  );
}

export default CoursePageCover;
