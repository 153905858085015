import './ProgramAccordion.css'
import { useState } from "react";
import ProgramAccordionItem from './ProgramAccordionItem/ProgramAccordionItem';

const ProgramAccordion = ({ courseProgramArr, isManSide, isDublicate }) => {
  const [openId, setId] = useState(null);

  
  return (
    <ul className={`program__accordion ${isDublicate ? "dublicate" : ""}`}>
      {courseProgramArr.map((item, id) => {
       return <ProgramAccordionItem onClick={() => id === openId ? setId(null) : setId(id) } isOpen={id===openId} item={item} key={id} isManSide={isManSide} />
      })}
    </ul>
  );
};

export default ProgramAccordion;
