import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom';
import { setBurgerIsClose } from '../../redux/BurgerSlice/BurgerSlice';
import './Burger.css'

function Burger() {
    const location = useLocation()
    const dispatch = useDispatch()
    const isWomanSide = location.pathname.includes("woman-side");

    const isActive = useSelector((state) => state.burger.isBurgerActive)

   const handleBurgerClose = () => {
    dispatch(setBurgerIsClose())
   }

    return (
        <div className={`burger__menu ${isActive ? "active" : ""}`}>
            <div className="burger__overlay">
                <div className="burger__body">
                    <Link onClick={handleBurgerClose} className='burger__link text-title-two' to={isWomanSide ? "/man-side" : "/woman-side"} >{isWomanSide ? "Чоловічий простір" : "Жіночий простір"}</Link>
                    <Link onClick={handleBurgerClose} className='burger__link text-title-two' to={"/all-courses"}>Всі курси</Link>
                    <Link onClick={handleBurgerClose} className='burger__link text-title-two' to={"/im-new"}>Я новенький</Link>
                </div>
            </div>
        </div>
    )
}

export default Burger