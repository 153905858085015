import './CourseProgram.css'
import ProgramAccordion from './ProgramAccordion/ProgramAccordion'

const CourseProgram = ({courseProgramArr, isManSide, isDublicate=false, isCouple=false}) => {
  return (
    <div className={`course__program ${isDublicate ? "dublicate" : ""}`}>
      {
        !isDublicate && 
        <h2 className={`${isManSide ? "text-title-one" : "text-title-one w"}`}>{isCouple ? "Програма курсів" : "Програма курсу"}</h2>
      }
        <ProgramAccordion isDublicate={isDublicate} courseProgramArr={courseProgramArr} isManSide={isManSide} />
    </div>
  )
}

export default CourseProgram