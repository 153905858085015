import "./AuthorsSection.css";
import instIcon from "../../assets/icons/instwomanicon.png";
import tgIcon from "../../assets/icons/tgwomanicon.png";
import ttIcon from "../../assets/icons/ttWomenIcon.png";
import instIconMan from "../../assets/icons/insticon.png";
import tgIcoMan from "../../assets/icons/tgicon.png";
import ttIconMan from "../../assets/icons/tticon.png";

function AuthorsSection({
  titleLeft,
  titleRight,
  descriptionFirstLeft,
  descriptionSecondLeft,
  imageLeft,
  imageRight,
  isWomanSide,
  descriptionFirstRight,
  descriptionSecondRight,
  brandNameLeft,
  brandNameRight,
}) {
  const isMobile = window.innerWidth <= 1199;

  return (
    <section className="authors__section">
      <div className="authors__section-wrapper">
        <div className="aithors__section-left">
          <p
            className={`authors-text text-title-one ${isWomanSide ? "w" : ""}`}
          >
            Автори курсів
          </p>
          <h2
            className={`authors__section-title-left text-title-two ${
              isWomanSide ? "w" : ""
            }`}
          >
            <span className={`${isWomanSide ? "woman-color" : "man-color"}`}>
              {brandNameLeft}
            </span>{" "}
            {titleLeft}
          </h2>
          <h2 className="authors__section-description body-text">
            {descriptionFirstLeft}
          </h2>
          <h2 className="authors__section-description-second body-text">
            {descriptionSecondLeft}
          </h2>
          <div className="authors__section-image-wrapper">
            <img
              src={isMobile ? imageRight : imageLeft}
              alt={`${isMobile ? titleRight : titleLeft} фото`}
              className="authors__section-image-left"
            />
            <div className="authors__section-links-left">
              <a
                target={"_blank"}
                rel={"noreferrer"}
                className="authors__section-link"
                href={
                  isMobile
                    ? isWomanSide
                      ? "https://www.instagram.com/tinakross__?igsh=ZTV2dHY5aHgxdzRr"
                      : "https://www.instagram.com/leon13__hormone/?hl=en"
                    : isWomanSide
                    ? "https://www.instagram.com/leon13__hormone/?hl=en"
                    : "https://www.instagram.com/tinakross__?igsh=ZTV2dHY5aHgxdzRr"
                }
              >
                <img
                  src={
                    isMobile
                      ? isWomanSide
                        ? instIcon
                        : instIconMan
                      : isWomanSide
                      ? instIconMan
                      : instIcon
                  }
                  alt="посилання на інстаграм"
                  className="authors__section-link-icon"
                />
              </a>
              <a
                target={"_blank"}
                rel={"noreferrer"}
                className="authors__section-link"
                href={
                  isMobile
                    ? isWomanSide
                      ? "https://t.me/+HxiWGYJdoRQ2YTYy"
                      : "https://t.me/+TkaZmEfQeEBiYmNi"
                    : isWomanSide
                    ? "https://t.me/+TkaZmEfQeEBiYmNi"
                    : "https://t.me/+HxiWGYJdoRQ2YTYy"
                }
              >
                <img
                  src={
                    isMobile
                      ? isWomanSide
                        ? tgIcon
                        : tgIcoMan
                      : isWomanSide
                      ? tgIcoMan
                      : tgIcon
                  }
                  alt="посилання на інстаграм"
                  className="authors__section-link-icon"
                />
              </a>
              <a
                target={"_blank"}
                rel={"noreferrer"}
                className="authors__section-link"
                href={
                  isMobile
                    ? isWomanSide
                      ? "https://www.tiktok.com/@1317_hub"
                      : "https://www.tiktok.com/@inst.leonhub_13?lang=en"
                    : isWomanSide
                    ? "https://www.tiktok.com/@inst.leonhub_13?lang=en"
                    : "https://www.tiktok.com/@1317_hub"
                }
              >
                <img
                  src={
                    isMobile
                      ? isWomanSide
                        ? ttIcon
                        : ttIconMan
                      : isWomanSide
                      ? ttIconMan
                      : ttIcon
                  }
                  alt="посилання на інстаграм"
                  className="authors__section-link-icon"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="aithors__section-right">
          <div className="authors__section-image-wrapper">
            <img
              src={isMobile ? imageLeft : imageRight}
              alt={`${isMobile ? titleLeft : titleRight} фото`}
              className="authors__section-image-right"
            />
            <div className="authors__section-links-left">
              <a
                target={"_blank"}
                rel={"noreferrer"}
                className="authors__section-link"
                href={
                  isMobile
                    ? isWomanSide
                      ? "https://www.instagram.com/leon13__hormone/?hl=en"
                      : "https://www.instagram.com/tinakross__?igsh=ZTV2dHY5aHgxdzRr"
                    : isWomanSide
                    ? "https://www.instagram.com/tinakross__?igsh=ZTV2dHY5aHgxdzRr"
                    : "https://www.instagram.com/leon13__hormone/?hl=en"
                }
              >
                <img
                  src={
                    isMobile
                      ? isWomanSide
                        ? instIconMan
                        : instIcon
                      : isWomanSide
                      ? instIcon
                      : instIconMan
                  }
                  alt="посилання на інстаграм"
                  className="authors__section-link-icon"
                />
              </a>
              <a
                target={"_blank"}
                rel={"noreferrer"}
                className="authors__section-link"
                href={
                  isMobile
                    ? isWomanSide
                      ? "https://t.me/+TkaZmEfQeEBiYmNi"
                      : "https://t.me/+HxiWGYJdoRQ2YTYy"
                    : isWomanSide
                    ? "https://t.me/+HxiWGYJdoRQ2YTYy"
                    : "https://t.me/+TkaZmEfQeEBiYmNi"
                }
              >
                <img
                  src={
                    isMobile
                      ? isWomanSide
                        ? tgIcoMan
                        : tgIcon
                      : isWomanSide
                      ? tgIcon
                      : tgIcoMan
                  }
                  alt="посилання на інстаграм"
                  className="authors__section-link-icon"
                />
              </a>
              <a
                target={"_blank"}
                rel={"noreferrer"}
                className="authors__section-link"
                href={
                  isMobile
                    ? isWomanSide
                      ? "https://www.tiktok.com/@1317_hub"
                      : "https://www.tiktok.com/@1317_hub"
                    : isWomanSide
                    ? "https://www.tiktok.com/@1317_hub"
                    : "https://www.tiktok.com/@1317_hub"
                }
              >
                <img
                  src={
                    isMobile
                      ? isWomanSide
                        ? ttIconMan
                        : ttIcon
                      : isWomanSide
                      ? ttIcon
                      : ttIconMan
                  }
                  alt="посилання на інстаграм"
                  className="authors__section-link-icon"
                />
              </a>
            </div>
          </div>
          <div className="authors__section-texts">
            <h2 className="authors__section-title-right text-title-two">
              <span className={`${isWomanSide ? "man-color" : "woman-color"}`}>
                {brandNameRight}
              </span>{" "}
              {titleRight}
            </h2>
            <h2 className="authors__section-description body-text">
              {descriptionFirstRight}
            </h2>
            <h2 className="authors__section-description-second body-text">
              {descriptionSecondRight}
            </h2>{" "}
          </div>
        </div>
      </div>
    </section>
  );
}

export default AuthorsSection;
