import { useRef, useEffect } from "react";
import plusIcon from '../../../../../assets/icons/CoursePlus.png'


function ProgramAccordionItem({ item, onClick, isOpen, isManSide }) {
  const itemRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      const height = itemRef.current.scrollHeight;
      itemRef.current.style.height = `${height}px`;
    } else {
      itemRef.current.style.height = "0px";
    }
  }, [isOpen]);

  return (
    <li className={`program__accordion-item`} onClick={onClick}>
      <button className={`program__accordion-header ${isManSide ? "text-title-two" : "text-title-two w"}`}>{item.title}</button>
      <div className="program__accordion-collapse" ref={itemRef}>
        <p className={`program__accordion-body-subtitle ${isManSide ? "man-color" : "woman-color"} body-text`}>{item.subtitle}</p>
        <div className="program__accordion-body-wrapper">
            {
                item.modules.map((module, idx) => {
                    return (
                        <p key={idx} className="program__accordion-body body-text">{isManSide ? "Тема " : "Урок "} <span>{module.courseCount}</span>: <span className={`${isManSide ? "man-color" : "woman-color"}`}>{module.courseName}</span>{module.text}</p>
                    )
                })
            }
        </div>
       
      </div>
      <img
        src={plusIcon}
        alt="зображення плюсика. при натисканні відкриється компонент відповіді"
        className={`program__plus__icon ${isOpen ? "active" : ""}`}
      />
    </li>
  );
}

export default ProgramAccordionItem;
